import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "customSelectContainer" }
const _hoisted_2 = { class: "customSelectLabel" }
const _hoisted_3 = ["tabindex"]
const _hoisted_4 = { class: "selectedValVisible" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.description), 1),
    _createElementVNode("div", {
      class: "customSelect",
      tabindex: _ctx.tabindex,
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = false))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["selected shadow", { open: _ctx.open }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
      }, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.selected), 1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["items", { selectHide: !_ctx.open }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsData, (option, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            onClick: ($event: any) => (_ctx.selectOption(option))
          }, _toDisplayString(option.title), 9, _hoisted_5))
        }), 128))
      ], 2)
    ], 40, _hoisted_3)
  ]))
}