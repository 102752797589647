
import {
  Ref,
  computed,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  ref,
} from "vue";

import type { CustomWindow } from "@/types/window";
import type { sfConfType } from "@/types/sfConfType";

import { showAsCurrency } from "@/services/showAsCurrency";
import { showAsPercent2 } from "@/services/showAsPercent";

import InputNumberButtons from "./ui/InputNumberButtons.vue";
import InputSelect from "./ui/InputSelect.vue";
import InputText from "./ui/InputText.vue";

import PdfTemplate from "./PdfTemplate.vue";

declare let window: CustomWindow;

import html2pdf from "html2pdf.js";

export default defineComponent({
  name: "WebWidget",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    campaignId: {
      type: String,
      required: true,
      default: "",
    },
  },
  components: {
    InputNumberButtons,
    InputSelect,
    InputText,
    PdfTemplate,
  },
  setup: function () {
    const sfConfig: Ref<sfConfType | null> = ref(null);
    const pdfContent = ref<HTMLDivElement | null>(null);

    const pdfGenerating = ref(false);
    const generationDate = ref("");

    const investName = ref("Nazwa inwestycji");
    const changedInvestName = (value: string) => {
      investName.value = value;
    };

    const purchasePrice = ref(475000);
    const changedPurchasePrice = (value: number) => {
      purchasePrice.value = value;
    };

    const courtFees = ref(800);
    const changedCourtFees = (value: number) => {
      courtFees.value = value;
    };

    const salesCommission = ref(0.5);
    const changedSalesCommission = (value: number) => {
      salesCommission.value = value;
    };

    const salesCommissionVal = computed(() => {
      const tmpVal = (purchasePrice.value / 100) * salesCommission.value;
      return tmpVal;
    });

    const pccTax = ref(2);
    const changedPccTax = (value: string) => {
      pccTax.value = Number(value);
    };
    const pccTaxItems = [
      { id: "0", title: "0 %" },
      { id: "2", title: "2 %" },
      { id: "6", title: "6 %" },
    ];

    const pccTaxVal = computed(() => {
      const tmpVal = (purchasePrice.value / 100) * pccTax.value;
      return tmpVal;
    });

    const renovationCosts = ref(2800);
    const changedRenovationCosts = (value: number) => {
      renovationCosts.value = value;
    };

    const totalCosts = computed(() => {
      const tmpVal =
        purchasePrice.value +
        courtFees.value +
        salesCommissionVal.value +
        pccTaxVal.value +
        renovationCosts.value;
      return tmpVal;
    });

    const monthlyCosts = ref(1700);
    const changedMonthlyCosts = (value: number) => {
      monthlyCosts.value = value;
    };

    const annualOccupancy = ref(85);
    const changedAnnualOccupancy = (value: number) => {
      annualOccupancy.value = value;
    };

    const monthlyRent = ref(3300);
    const changedMonthlyRent = (value: number) => {
      monthlyRent.value = value;
    };

    const annualCost = computed(() => {
      const tmpVal = monthlyCosts.value * 12;
      return tmpVal;
    });

    const annualIncome = computed(() => {
      const tmpVal = monthlyRent.value * 12 * (annualOccupancy.value / 100);
      return tmpVal;
    });

    const operationalIncome = computed(() => {
      const tmpVal = annualIncome.value - annualCost.value;
      return Number(tmpVal.toFixed(2));
    });

    const profitability = computed(() => {
      const tmpVal = (operationalIncome.value / totalCosts.value) * 100;
      return tmpVal.toFixed(2);
    });

    const paybackPeriod = computed(() => {
      const tmpVal = Math.round(totalCosts.value / operationalIncome.value);
      return tmpVal;
    });

    const printPdf = () => {
      pdfGenerating.value = true;
      let now = new Date();
      generationDate.value = now.toLocaleString("pl-PL");

      nextTick(() => {
        if (pdfContent.value) {
          html2pdf().from(pdfContent.value.innerHTML).save("wyliczenia.pdf");

          setTimeout(() => {
            pdfGenerating.value = false;
          }, 2000);
        }
      });
    };

    const inputForceUpdate = ref(1);

    const showResults = ref(false);

    const instance = getCurrentInstance();
    const sanitize = instance?.appContext.config.globalProperties.$sanitize;

    onMounted(() => {
      const widgetConfig = window.widgetConfig;
      if (typeof widgetConfig !== "undefined") {
        sfConfig.value = widgetConfig;
      }
    });

    return {
      sfConfig,
      generationDate,
      investName,
      changedInvestName,
      purchasePrice,
      changedPurchasePrice,
      courtFees,
      changedCourtFees,
      salesCommission,
      salesCommissionVal,
      changedSalesCommission,
      pccTax,
      pccTaxItems,
      pccTaxVal,
      changedPccTax,
      renovationCosts,
      changedRenovationCosts,
      totalCosts,
      monthlyCosts,
      changedMonthlyCosts,
      annualOccupancy,
      changedAnnualOccupancy,
      monthlyRent,
      changedMonthlyRent,
      annualCost,
      annualIncome,
      profitability,
      operationalIncome,
      paybackPeriod,
      printPdf,
      showResults,
      pdfContent,
      showAsCurrency,
      showAsPercent2,
      inputForceUpdate,
      pdfGenerating,
    };
  },
});
