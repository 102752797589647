
import { defineComponent } from "vue";

import { showAsCurrency } from "@/services/showAsCurrency";
import { showAsPercent2 } from "@/services/showAsPercent";

import IconSpeedfin from "@/components/IconSpeedfin.vue";

export default defineComponent({
  name: "PdfTemplate",
  props: [
    "generationDate",
    "investName",
    "purchasePrice",
    "courtFees",
    "salesCommission",
    "salesCommissionVal",
    "pccTax",
    "pccTaxVal",
    "renovationCosts",
    "totalCosts",
    "monthlyCosts",
    "annualOccupancy",
    "monthlyRent",
    "annualCost",
    "annualIncome",
    "profitability",
    "operationalIncome",
    "paybackPeriod",
  ],
  components: {
    IconSpeedfin,
  },
  setup: function () {
    return {
      showAsCurrency,
      showAsPercent2,
    };
  },
});
